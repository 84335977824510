<template>
  <div>
    <hero-bar>
      <span class="capitalize"> {{ title }} </span>
      <router-link slot="right" :to="goTo" class="button">
        {{ $getTranslation('general.back') }}
      </router-link>
    </hero-bar>

    <section class="section is-main-section">
      <card-component icon="account">
         <ValidationObserver ref="observer" v-slot="{ passes }">
          <form ref="form" @submit.prevent="handleSubmit">
            <!-- <InputWithValidation
              horizontal
              rules="required"
              type="title"
              v-model="form.title"
              provider="provider"
              :responseError="error"
              :label="$getTranslation('form.title.label')"
              :placeholder="$getTranslation('form.title.placeholder')"
            /> -->
            <b-field horizontal :label="$getTranslation('form.title.label')" class="capitalize">
              <b-input v-model="form.title" type="text">{{ form.title }}</b-input>
            </b-field>
            <b-field horizontal :label="$getTranslation('form.category.label')" class="capitalize">
              <b-input v-model="form.category" type="text">{{ form.category }}</b-input>
            </b-field>
            <b-field horizontal :label="$getTranslation('form.category.label')" class="capitalize">
              <b-select placeholder="Select a category">
                  <option
                      v-for="category in form.category"
                      :value="category.id"
                      :key="category.id">
                      {{ category.name }}
                  </option>
              </b-select>
            </b-field>
            <hr />
            <b-field horizontal>
              <b-field grouped>
                <div class="control">
                  <b-button native-type="submit" type="is-primary"  @click="passes(submit)">{{ $getTranslation('form.submit') }}</b-button>
                </div>
                <div class="control" v-if="item !== null">
                  <b-button type="is-primary is-outlined" @click="reset">{{ $getTranslation('form.reset') }}</b-button>
                </div>
              </b-field>
            </b-field>
          </form>
        </ValidationObserver>
      </card-component>
    </section>
  </div>
</template>

<script>
// Form for crete and edit roles and permissions (they both have same db
// structure)
import { mapGetters, mapActions } from "vuex"
import { ApiScales } from '@/api-routes.js'
import FormTemplateMixin from '@/mixins/FormTemplateMixin'

export default {
  name: 'app-create',
  props: ['id'],
  mixins: [FormTemplateMixin],

  data () {
    /*authors: null
category: "work"
code: null
created_at: "2019-11-22 09:12:57"
created_by: 2
deleted_at: null
description: null
development: null
id: 100
instructions: null
keywords: null
parent_id: null
private: 1
quality: null
source: null
status: "draft"
theory: null
title: "PF Work Fatigue 03 - Emotionale arbeitsbezogene Erschöpfung"
updated_at: "2019-11-22 09:12:57"*/
    return {
      title: this.$route.meta.routeName,
      endpoint: ApiScales,
      // formSession are saved old valus needed for reset
      formSession: {
        title: null,
        category: null,
        id: null
      }
    }
  },
  computed: {
    form: {
      get: function () {
        let form = {};

        // form is empty if item is created, has props from formSession
        form = this.formSession

        // On create item, id is not defined, form is empty
        if (typeof this.id === "undefined" || this.id === null) {
          return form
        }

        // Assign data from backend to the form, and save values in formSession
        // for reset action
        this.setFormValues (form)
        return form
      },
      // setter needed to reset the form
      set: function(form) {
        this.setFormValues (form)
      }
    },

    goTo() {
      return '/' + this.moduleName
    }
  },
  methods: {
    /**
     * submit will dynamically handle create or update action according to the
     * method defined
     */
    submit () {
      let payload = {
        method: this.formMethod,
        data: this.fetchData()
      }
      if(typeof(this.id) !== 'undefined' && this.id.length) {
        payload.endpoint = this.endpoint + '/' + this.id
      } else {
        payload.endpoint = this.endpoint
        // console.log('payload: ', payload)
      }
      this.saveItem(payload)
        .then(response => {
          // console.log("form submit: ", baseUrl);
          // clean objects
          this.formSession =  {}
          // location.reload();
          this.redirectTo();
        })
        .catch(error => {
          console.log("ERROR form submit: ", error)
        });
    },

    reset () {
      this.form = this.formSession
    },

    setFormValues (form) {
      if(this.item !== null) {
        // let item = this.item.data
        // console.log(this.item.data)
        form.id= this.item.id,
        form.title= this.item.title,
        form.category= this.item.category

        // console.log('set form: ', form this.formSession)
        this.formSession = form
      }
    }
  },

  created () {
    let payload = {}
    // console.log('form created: ', this.id, this.$route.meta.type, this.$route.meta.routeName)
    this.moduleName = this.$route.meta.type

    // console.log('id? ', this.id, this.formMethod, 'typeof this.id or null - not working!!')
    if(typeof(this.id) !== "undefined" && this.id.length) {
      this.endpoint += this.moduleName + '/' + this.id
      // this.title = this.$getTranslation('form.general.edit') + ' ' +  this.$getTranslation('general.views.' + this.$route.meta.type , 0)
      payload = {
        endpoint: this.endpoint,
        type: this.moduleName
      }
      this.formMethod = 'put'
      this.fetchItem(payload)
      // console.log( ' id known: ', this.id, payload)
    } else {
      // create new, nothing to fetch
      this.endpoint += this.moduleName
      payload = {
        endpoint: this.endpoint,
        type: this.moduleName
      }
      // console.log('payload: ', payload)
    }
    // console.log('form method? ', this.formMethod)
  }
}
</script>
